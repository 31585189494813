/* #region BlogTest (tkt mael je vais l'enlever dans le future) (sa me fait drolement chier bro que tu te permette) */
.BlogTest {
  margin: calc(5rem + 5vmin);
}
/* #endregion */
/* #region  Title */
.BlogHeader h1 {
  color: rgb(10, 10, 10);
  font-size: var(--h1-);
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: var(--main-color-hover-);
  margin-bottom: var(--space-pink-);
}
.BlogHeader .author {
  margin-bottom: var(--space-blue-);
}
.blog-components-frame {
  width: 100%;
}
.blog-components-frame label {
  font-size: var(--font-size-1-);
  display: flex;
  flex-direction: column;
  color: black;
}
.blog-components-frame input {
  font-size: var(--font-size-1-);
  background: white;
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  border: 2px solid var(--main-color-);
}
.blog-components-frame .edit-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.blog-components-frame .edit-container label {
  width: 100%;
}
.blog-components-frame .blog-edit-component {
  position: relative;
}
.blog-components-frame .blog-edit-component .Button .btn-edit-component {
  position: absolute;
  display: none;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.component:hover .blog-edit-component .Button .btn-edit-component {
  display: block;
}
.BlogHeader .blog-edit-component .author {
  font-size: var(--font-size-0-);
  margin-left: 0.5rem;
}
.BlogHeader .blog-edit-component .author span {
  color: var(--secondary-color-hover-);
}
/* #endregion */
/* #region  TitreH2 */
.TitreH2 h2 {
  font-size: var(--h2-);
  color: var(--main-color-);
  font-weight: 500;
  margin-bottom: var(--space-blue-);
}
.title-input input {
  min-width: var(--min-width-500-);
}
/* #endregion */
/* #region  TitreH3 */
.TitreH3 h3 {
  font-size: var(--h3-);
  color: var(--main-color-);
  font-weight: 500;
  margin-bottom: var(--space-blue-);
}
.title-input input {
  min-width: var(--min-width-500-);
}
/* #endregion */ /* #region  Paragraphe */
/* #region  Paragraphe */
.ParagrapheBlog p {
  line-height: 190%;
  width: 100%;
}
.ParagrapheBlog {
  margin-bottom: var(--space-green-);
}
.ParagrapheBlog .edit-container textarea {
  margin-bottom: 1.5rem;
  min-height: 15rem;
}
.ParagrapheBlog .Paragraphe a {
  color: var(--link-color-);
  text-decoration: none;
}
.ParagrapheBlog .Paragraphe a:hover {
  text-decoration: underline;
}
/* #endregion */
/* #region  LinkList */
.LinkList {
  font-size: var(--font-size-1-);
  margin-left: 2rem;
  margin-bottom: var(--space-green-);
}
.LinkList li {
  margin-bottom: 2.2rem;
}
.child-list li {
  margin-bottom: 1.5rem;
}
.LinkList .child-list li:last-child {
  margin-bottom: 2.2rem;
}
.LinkList .last-item li {
  margin-bottom: 0;
}
.LinkList li a,
.LinkList li a:visited {
  text-decoration: none;
  color: var(--link-color-);
}
.LinkList li a:hover {
  text-decoration: underline;
}
.child-list {
  margin-left: 1.7rem;
}
.child-list-edit {
  margin-left: calc(5% + 3rem);
}
.content-row .text-input {
  width: auto;
}
.add-btn-container {
  margin-left: 1rem;
}
/* #endregion */
/* #region  FullImage */
.FullImage {
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: var(--space-green-);
  min-height: 100px;
}
.FullImage img {
  object-fit: cover;
  width: 100%;
}
/* #endregion */
/* #region  ActionCode */
.ActionCode li {
  margin-bottom: var(--space-pink-);
  margin-left: calc(2rem + 2.5vw);
  font-size: var(--font-size-1-);
}
.ActionCode .code {
  margin-bottom: var(--space-red-);
  margin-left: calc(1rem + 2vw);
  font-size: var(--font-size-0-);
  position: relative;
}
.ActionCode .code.close {
  height: 20rem;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.ActionCode .code.close::after {
  content: "...";
  color: white;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: 4rem;
  font-size: 5rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 70%;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(28, 30, 32, 1) 8%,
    rgba(28, 30, 32, 0.6559757692139356) 60%,
    rgba(0, 212, 255, 0) 100%
  );
}
.ActionCode .code pre {
  border-radius: 15px !important;
  margin: 0 !important;
}
.ActionCode .code .btn-container {
  position: absolute;
  top: 15px;
  right: 15px;
}
.ActionCode .code .btn-container .Button {
  display: block;
  position: relative;
  transform: none;
}
.ActionCode .code .btn-container .Button button {
  background-color: rgb(68, 68, 68);
  color: rgb(189, 189, 189);
  border-radius: 5px;
}
.ActionCode .code .btn-container .Button button:hover {
  background-color: rgb(53, 53, 53);
}
.ActionCode #code-editor {
  width: 100% !important;
  font-size: var(--font-size-0-) !important;
}
.ActionCode .see-more-btn {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 4;
}
.ActionCode .see-more-btn .Button button {
  color: white;
  font-size: 18px;
  z-index: 4;
  font-weight: 300;
  background: rgba(90, 90, 90, 0.349);
  border-radius: 5px;
}
.ActionCode .see-more-btn .Button button:hover {
  background: rgba(63, 63, 63, 0.349);
}
/* #endregion */
/* #region  ActionImage */
.ActionImage {
  margin-left: calc(2rem + 2.5vw);
}
.ActionImage li {
  font-size: var(--font-size-1-);
  margin-bottom: var(--space-pink-);
}
.ActionImage .FullImage {
  margin-bottom: var(--space-red-);
}
/* #endregion */

/* #region  Général Component Blog */
.content-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.edit-content {
  width: 100%;
}
/* #endregion */

.blog {
  display: flex;
  flex-direction: column;
}
.blog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: calc(1.4rem + 3vw) calc(0.2rem + 3vw);
  border-radius: 20px;
  margin: calc(0.5rem + 3vw);
  margin-top: 100px;
}
.blog-content .component {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}
.blog-content .dropdown-components {
  width: 300px;
  margin-bottom: 2rem;
}
.blog-content button {
  background: var(--secondary-color-);
  border: none;
  font-size: var(--font-size-1-);
  padding: 0.5rem 2rem;
  width: fit-content;
  transition: all 0.3s;
}
.blog-content button:hover {
  background: var(--secondary-color-hover-);
  cursor: pointer;
}
.blog-content .edit-component-container {
  margin-top: 2rem;
}
.blog-content .edit-component-container h2 {
  font-size: var(--font-size-2-);
  font-weight: 400;
  margin-bottom: 2rem;
}
.blog-content .edit-component-container input {
  font-size: var(--font-size-1-);
  background: var(--bg-secondary-color-);
  padding: 0.5rem 1rem;
  border: none;
  margin: 1rem 0;
}
.blog-content .edit-component-container label {
  font-size: var(--font-size-1-);
  display: flex;
  flex-direction: column;
  color: black;
}
.blog-content .component:hover {
  box-shadow: 0 0 5px 5px var(--secondary-color-);
}
.btn-add-component {
  margin-top: 2rem;
}
.text-input {
  width: auto;
}
.blog-content .btn-move {
  background-color: rgba(255, 255, 255, 0);
}
.blog-content .btn-move:hover {
  background-color: rgba(255, 255, 255, 0);
  cursor: grab;
}
.blog-content .btn-move-container {
  position: absolute;
  display: block;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}
.blog-content .save-btn {
  margin-top: 2rem;
}
.blog-content .back-to-dashboard {
  align-self: flex-start;
  margin-bottom: 1rem;
}
.blog-content .back-to-dashboard .Button button {
  border-radius: 10px;
}
.blog-content .image-blog {
  margin: 1rem 0;
}
.blog-content .image-blog img {
  width: 400px;
}
.dropdown {
  z-index: 3;
}
.blog-content .edit-detail-container {
  width: 100%;
  padding: 5rem 0 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.edit-detail-container .edit-details {
  padding-right: 6rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.url-input input {
  max-width: none;
}
.url-input {
  width: 100%;
}

/* #region  Navbar  */
/*#region   menu  */
.navbar {
  display: flex;
  align-items: center;
  padding: 3rem calc(2.5rem + 2vw);
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
  max-height: 100px;
}
.navbar.sticky {
  /* background-color: var(--bg-main-color-); */
  backdrop-filter: blur(20px);
}
.navbar.sticky.notBlurry {
  background-color: var(--bg-main-color-);
}
.navbar img {
  max-width: calc(10rem + 7vw);
}
.navbar .navbar-list {
  color: white;
  display: flex;
  list-style: none;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: calc(0.6rem + 1vw);
  font-weight: 300;
  letter-spacing: 1px;
  padding: 0 0 0 calc(1rem + 2vw);
}
.navbar .navbar-list li {
  margin: 0 calc(0.5rem + 0.2vw);
  padding: 0.5rem 1.3vw;
  white-space: nowrap;
}
.navbar .navbar-list li a {
  text-decoration: none;
  color: white;
}
.navbar .navbar-list li a:hover {
  cursor: pointer;
  font-weight: 500;
}
.navbar .navbar-list li:last-child .Button button {
  background-color: white;
  color: var(--main-color-);
  font-weight: 400;
  padding: 0.5rem 2rem;
  transition: all 0.3s ease;
}
.navbar .navbar-list li:last-child:hover .Button button {
  background-color: var(--main-color-);
  color: white;
}
.navbar .menu-item {
  color: white;
  list-style: none;
  font-size: calc(0.6rem + 1vw);
  font-weight: 300;
  letter-spacing: 1px;
  background: none;
  border: none;
}
.navbar .menu-item:hover {
  cursor: pointer;
  font-weight: 500;
}
/* #endregion */
/*#region   hamburger  */
.menu-hamburger {
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 50px;
  position: relative;
  z-index: 101;
  min-height: 50px;
}
.menu-hamburger:hover {
  cursor: pointer;
}
.menu-hamburger .bars {
  height: 3px;
  width: 100%;
  background-color: white;
  margin: 0.5rem 0;
  transition: all 0.5s ease;
  transform-origin: center;
}
.menu-hamburger .bars.bar-1 {
  width: 60%;
  transition: transform 0.5s ease 0s, width 0.5s ease 0.5s;
  transform: rotate(0deg);
}
.menu-hamburger .bars.bar-2 {
  width: 80%;
}
.menu-hamburger .bars.bar-3 {
  transition: transform 0.5s ease 0s;
}
.menu-hamburger.is-open .bars.bar-1 {
  width: 100%;
  transform: rotate(405deg);
  transition: transform 0.5s ease 0.2s, width 0.5s ease 0s;
  margin: 0;
  position: absolute;
}
.menu-hamburger.is-open .bars.bar-2 {
  display: none;
}
.menu-hamburger.is-open .bars.bar-3 {
  transform: rotate(-405deg);
  transition: transform 0.5s ease 0.2s;
  margin: 0;
}
/* #endregion */
/*#region   menu mobile  */
.menu-mobile {
  position: fixed;
  height: 100%;
  width: 60%;
  /*   background: linear-gradient(
      203.76deg,
      var(--bg-main-color-) -7.12%,
      #180929 42.25%,
      var(--bg-main-color-) 104.92%
    ),
    var(--bg-main-color-); */
  background-color: var(--bg-main-color-);
  top: 0;
  bottom: 0;
  right: 0;
  transform-origin: right;
  display: flex;
  transform: translateX(100%) scaleX(0);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: calc(10rem + 4vh) 0;
  transition: transform 0.5s ease;
  min-height: 100vh;
  z-index: 100;
}
.navbar .menu-hamburger.is-open ~ .menu-mobile {
  transform: translateX(0) scaleX(100%);
}
.menu-mobile .menu-mobile-logo {
  margin-bottom: 6vh;
  /* margin-left: -20px; */
  max-width: unset;
  width: 80%;
  padding-bottom: 2rem;
  display: flex;
  justify-content: baseline;
  /* border-bottom: 1px solid white; */
}
.menu-mobile .mobile-logo-a {
  display: flex;
  justify-content: center;
}
.menu-mobile .menu-mobile-list {
  color: white;
  list-style: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.menu-mobile .menu-mobile-list li {
  font-size: calc(1.3rem + 1.8vw);
  opacity: 0;
  letter-spacing: 1px;
  font-family: "Exo 2", sans-serif;
  font-weight: 600;
  color: var(--secondary-color-hover-);
}
.menu-mobile .menu-mobile-list li a {
  text-decoration: none;
  color: var(--secondary-color-);
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.438);
  transition: all 0.3s ease;
}
.menu-mobile .menu-mobile-list li a:hover {
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0);
}
.menu-mobile .menu-mobile-list li:nth-child(1) {
  transition: all 0.5s ease-in-out 0.2s;
}
.menu-mobile .menu-mobile-list li:nth-child(2) {
  transition: all 0.5s ease-in-out 0.3s;
}
.menu-mobile .menu-mobile-list li:nth-child(3) {
  transition: all 0.5s ease-in-out 0.4s;
}
.menu-mobile .menu-mobile-list li:nth-child(4) {
  transition: all 0.5s ease-in-out 0.5s;
}
.menu-mobile .menu-mobile-list li:nth-child(5) {
  transition: all 0.5s ease-in-out 0.6s;
}
.menu-mobile .menu-mobile-list li.fade {
  opacity: 1;
}
/* #endregion */
/* #endregion */
/* #region  BeforeAfterSlider */
.before-after-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: 400px;
}

.before-image,
.after-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.before-image {
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: ew-resize;
}
/* #endregion */
/* #region  StatCircle  */
.circleStat {
  display: flex;
  align-items: center;
}

.circleStat .stat--circle .stat {
  /*   background: linear-gradient(
    34.66deg,
    #480988 17.74%,
    #a551fa 65.44%,
    rgba(255, 255, 255, 0.67) 87.27%
  ); */
  background: url(../img/btn-bg.png);
  background-size: cover;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  position: relative;
  border-radius: 50%;
  isolation: isolate;
  font-family: "Sanchez", serif;
}
/* .circleStat .stat--circle .stat::after {
  content: "";
  position: absolute;
  top: auto;
  left: auto;
  width: 90px;
  height: 90px;
  background: var(--bg-main-color-);
  border-radius: 50%;
  z-index: -1;
} */
.circleStat .stat--text {
  color: white;
  font-size: 20px;
  margin-left: 20px;
}
/* #endregion */
/* #region  TitleSection  */
.title-section {
  display: flex;
  flex-direction: column;
  color: white;
}
.title-section .above-title {
  font-size: 18px;
  text-align: center;
  font-family: "Roboto";
  font-weight: 300;
}
.title-section .title-h2 {
  font-size: calc(var(--h2-main-) + 1vw);
  text-align: center;
  font-family: "Exo 2", sans-serif;
  font-weight: 400;
}
/* #endregion */
/* #region  IconBox  */
.iconBox {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  min-width: 250px;
  text-align: center;
  align-items: center;
  min-height: calc(20rem + 7vw);
  margin: 2rem 0;
}
.iconBox .icon-circle {
  background: linear-gradient(
    34.66deg,
    #480988 17.74%,
    #a551fa 65.44%,
    rgba(255, 255, 255, 0.67) 87.27%
  );
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  margin-bottom: 2rem;
  width: 116px;
  height: 116px;
}
.iconBox:hover .icon-circle {
  transform: translateY(-10px);
  box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.178);
}
.iconBox .icon-circle img {
  width: 90%;
}
.iconBox .icon-title {
  font-size: calc(1.5rem + 0.7vw);
  color: white;
  font-weight: 400;
  margin-bottom: 2rem;
}
.iconBox .icon-desc {
  color: white;
  font-size: calc(1.2rem + 0.6vw);
  line-height: 1.7;
  font-weight: 300;
}
.iconBox .icon-button {
  color: white;
  text-decoration: none;
  font-weight: 300;
  font-size: calc(1rem + 0.4vw);
  border: 1px solid white;
  border-radius: 40px;
  padding: 1rem 3rem;
  transition: all 0.3s ease;
}
.iconBox .icon-button:hover {
  background: white;
  color: var(--main-color-);
}
/* #endregion */
/* #region  StepTitle  */
.step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  /* transition: all 0.5s ease; */
}
.step--number {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}
.step--number span {
  display: block;
  height: 80px;
  width: 4px;
  background: linear-gradient(
    0deg,
    #480988 0%,
    rgba(165, 81, 250, 0.53) 48.75%,
    rgba(179, 166, 191, 0.31) 81.04%,
    rgba(181, 181, 181, 0) 100%
  );
}
.step--number p {
  font-size: calc(1.5rem + 1vw);
  color: white;
  text-align: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(
    34.66deg,
    #480988 17.74%,
    #a551fa 65.44%,
    rgba(255, 255, 255, 0.67) 87.27%
  );
}
.step--title {
  font-size: calc(var(--h2-main-) - 0.5vw);
  text-align: center;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  color: white;
}
.step--text {
  width: 65%;
  min-width: 325px;
  margin-left: 50px;
}
.step--text p {
  color: white;
  font-size: calc(var(--p-main-) + 0.2vw);
  line-height: 1.5;
}

/* #endregion */
/* #region  ArrowUp  */
.arrow-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: var(--main-color-);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 10;
}
.arrow-top:hover {
  cursor: pointer;
}
.arrow-top img {
  transform: rotate(-45deg);
  transition: all 0.5s ease;
}
.arrow-top:hover img {
  transform: translateY(-5px) rotate(-45deg);
}
/* #endregion */
/* #region  TitleLeft  */
.title-h2-l {
  font-size: calc(var(--h2-main-) + 0.8vw);
  text-align: left;
  font-family: "Exo 2", sans-serif;
  font-weight: 400;
  color: white;
}
/* #endregion */
/* #region  DevisGratuit  */
.devis-gratuit a {
  display: block;
  font-size: calc(var(--p-main-) + 0.5rem);
  color: var(--bg-main-color-);
  text-decoration: none;
  background-color: var(--secondary-color-);
  padding: 0.6rem 2rem;
  border-radius: 10px;
  margin-top: 5rem;
  width: fit-content;
  transition: all 0.5s ease;
  text-align: center;
}
.devis-gratuit a:hover {
  background-color: var(--secondary-color-hover-);
}
/* #endregion */
/* #region  QuestionFaq  */
.faq--tab {
  position: relative;
  padding: 2rem calc(2rem + 4vw) 2rem 2rem;
  border-radius: 5px;
  border: 1px solid var(--secondary-color-hover-);
  overflow: hidden;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all 0.3s ease;
}
.faq--tab:hover {
  transform: scale(1.02);
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.151);
}
.faq--tab input {
  appearance: none;
  width: 100%;
  display: none;
}
.faq--tab label {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.faq--tab label::after {
  /* signe plus (+) */
  content: "+";
  position: absolute;
  right: 20px;
  font-size: var(--font-size-1-);
  color: var(--secondary-color-);
  transition: transform 1s ease;
}
.faq--tab:hover label::after {
  color: var(--secondary-color-hover-);
}
.faq--tab input.revealTarif ~ label::after {
  transform: rotate(135deg);
  color: #fff;
  top: 10px;
}
.faq--tab label h3 {
  /*  numero de question  */
  width: 40px;
  height: 40px;
  background: #333;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.25em;
  margin-right: 15px;
  flex-shrink: 0;
  font-size: var(--p-main-bigger-);
  font-weight: 400;
}
.faq--tab label h3 {
  background: linear-gradient(135deg, rgb(173, 109, 247), var(--main-color-));
}
.faq--tab input.revealTarif ~ label h3 {
  /* chiffre lorsque l'on clique  */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.2);
  font-size: 10em;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 0px;
}
.faq--tab label h4 {
  font-size: var(--p-main-bigger-);
  color: white;
  font-weight: 500;
  line-height: 25px;
}
.faq--tab input.revealTarif ~ label h4 {
  position: relative;
  font-weight: 500;
  color: #fff;
  z-index: 10;
  margin-bottom: 1rem;
}
.faq--tab input.revealTarif ~ .faq--content p {
  color: #fff;
  font-size: var(--p-main-);
  display: block;
  line-height: 1.7;
}
.faq--tab .faq--content {
  max-height: 0;
  transition: all 0.5s ease;
  overflow: hidden;
}
.faq--tab input.revealTarif ~ .faq--content {
  max-height: 100vh;
}
.faq--tab .faq--content p {
  position: relative;
  padding-inline-start: 10px 0;
  color: #fff;
  z-index: 10;
  margin-left: 1rem;
  display: none;
}
/* #endregion */
/* #region  HoverImage */
.hover-image {
  display: block;
  position: relative;
  transition: all 0.5s ease;
}
.hover-image:hover {
  transition: all 0s ease;
}
/* #endregion */
/* #region  Dropdown */
.dropdown {
  color: black;
  font-size: var(--font-size-0-);
}
/* #endregion */
/* #region  Button */
.Button button {
}
/* #endregion */
/* #region  TextInput */
.text-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  width: 100%;
}
.text-input label {
  font-size: calc(var(--p-main-) - 0.3rem);
  margin-left: 1rem;
  font-family: "Roboto", sans-serif;
  color: #333;
}
.text-input input {
  border-radius: 40px;
  border: 2px solid black;
  padding: 0.2rem 1rem;
  font-size: var(--p-main-);
  max-width: 350px;
  width: 100%;
}
/* #endregion */
/* #region  TextArea */
.text-area {
  width: 100%;
}
.text-area textarea {
  width: 100%;
  font-size: var(--font-size-1-);
  font-weight: 400;
  border: none;
  color: black;
  padding: 0 0.5rem;
  border-radius: 4px;
  outline: 2px solid var(--main-color-hover-);
  resize: vertical;
}
/* #endregion */
/* #region  SkeletonBlogCard */
.SkeletonBlogCard .description {
  min-height: 70px;
}
.SkeletonBlogCard .description .react-loading-skeleton {
  height: 70px;
}
.SkeletonBlogCard .status span.react-loading-skeleton,
.SkeletonBlogCard .status span,
.SkeletonBlogCard .date-created span {
  display: block;
  height: 20px;
  width: 30px;
  padding: 0px !important;
}
.SkeletonBlogCard .status-category .status {
  padding: 0px !important;
  background: none !important;
}
.SkeletonBlogCard .status-category .status .react-loading-skeleton {
  height: 25px;
  width: 50px;
  transform: translateX(-25px);
}
.SkeletonBlogCard .status-category .status span {
  background: none !important;
}
/* #endregion */

/* #region   Media-1156px */
@media screen and (max-width: 1156px) {
  .step--text {
    text-align: center;
    margin-left: 0;
  }
}
/* #endregion */
/* #region   Media-950px */
@media screen and (max-width: 950px) {
  .navbar .navbar-list {
    display: none;
  }
  .navbar .menu-hamburger {
    display: flex;
  }
}
/* #endregion */
/* #region   Media-450px */
@media screen and (max-width: 450px) {
  .step--text {
    min-width: unset;
    width: 100%;
  }
}
/* #endregion */

@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&family=Sanchez&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
}

:root {
  font-size: 62.5%;
  --bg-main-color-: #080010;
  /*   --bg-main-color-: #0e001b; */
  --bg-secondary-color-: #db5afb;
  --main-color-: #420983;
  --main-color-hover-: #29093d;
  --secondary-color-: #dec0fd;
  --secondary-color-hover-: #ba74ff;
  --link-color-: #9b43ff;
  --red-: #ff4949;
  --red-hover-: rgb(202, 55, 55);
  --green-: rgb(69, 179, 69);
  --padding-main-: calc(2rem + 5vw);
  --padding-second-: calc(6rem + 4vw);
  --h2-main-: calc(2rem + 2.5vw);
  --p-main-: calc(1.2rem + 0.5vw);
  --p-main-bigger-: calc(1.2rem + 0.8vw);
  --font-size-xsmall-: calc(1rem + 0.1vw);
  --font-size-0-: calc(1.3rem + 0.2vw);
  --font-size-05-: calc(1.4rem + 0.3vw);
  --font-size-1-: calc(1.4rem + 0.4vw);
  --font-size-2-: calc(1.6rem + 0.8vw);
  --font-size-3-: calc(1.7rem + 1.2vw);
  --h1-: calc(2rem + 2vw);
  --h2-: calc(2rem + 1vw);
  --h3-: calc(1.5rem + 1vw);
  --delay-base: 2s;
  --space-blue-: 1.5rem;
  --space-pink-: calc(var(--space-blue-) * 1.7);
  --space-red-: calc(var(--space-blue-) * 2.2);
  --space-green-: calc(var(--space-blue-) * 2.7);
  --min-width-500-: 500px;
}
body {
  /* background: url("./img/body-bg-2-3.webp"); */
  background-size: cover;
  overflow-x: hidden;
  background-color: #080010;
  max-width: 100vw;
}
p {
  font-size: var(--font-size-1-);
  font-size: 400;
}

/* #region  Général */
.btn-container {
  display: flex;
  gap: 10px;
}
.red {
  background: var(--red-) !important;
}
.white {
  color: white;
}
.secondary-color {
  color: var(--secondary-color-);
}
.bg-color {
  color: var(--bg-main-color-);
}
.c-main {
  color: var(--bg-main-color-);
}
.row {
  display: flex;
  align-items: center;
  gap: 15px;
}
.w-100 {
  width: 100%;
}
.font-size-1 {
  font-size: var(--font-size-1-);
}
.font-weight-400 {
  font-weight: 400;
}
.min-height-250 {
  height: 250px;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mt-2 {
  margin-top: 2rem;
}
.flex-grow-1 {
  flex-grow: 1;
}
.success-message {
  color: var(--green-);
  margin-top: 2rem;
}
.loading {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}
.loading h2 {
  font-size: var(--font-size-3-);
  font-weight: 400;
  color: var(--main-color-);
}
.loading .loading-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background: conic-gradient(
    from 90deg at 50% 50%,
    #29093d 0%,
    #29093d 50%,
    #3d0d5a 70%,
    white 82%,
    #3d0d5a 92%,
    #29093d 100%
  );
  height: 50px;
  width: 50px;
  border-radius: 50%;
  animation: 1.6s rotate cubic-bezier(0.8, 0.09, 0.19, 0.61) infinite reverse;
}
@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.loading .loading-circle .inner-circle {
  display: block;
  background: white;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}
/* #endregion */

/*#region   Accueil  */
.home {
  background-size: cover;
  background-color: #080010;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  padding: 15vh var(--padding-main-) 3rem var(--padding-main-);
  width: 100%;
}
.home .home-content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: calc(1rem + 5vh);
  flex-wrap: wrap;
}
.home .home-content .home--textContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 60%;
}
.home .home-content .home--text {
  max-width: 500px;
}
.home .home-content .home--text .home-bande {
  font-size: calc(1rem + 0.5vw);
  padding: 0.5rem 2rem;
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0.31) 0%,
    rgba(217, 217, 217, 0) 104.17%
  );
  border-radius: 7px;
  display: flex;
  align-items: center;
}
.home .home-content .home--text .home-bande span {
  font-size: calc(1rem + 0.8vw);
  color: var(--main-color-);
  margin-right: 1rem;
}
.home .home-content .home--text h1 {
  font-size: calc(3.5rem + 2.5vw);
  font-weight: 400;
  font-family: "Exo 2", sans-serif;
  line-height: calc(4rem + 4vw);
  margin-bottom: 2rem;
  max-width: calc(20rem + 20vw);
  overflow: hidden;
}
.home .home-content .home--text h1 span {
  display: block;
}
.gradient-word {
  background: linear-gradient(81.41deg, #6213bb 15.31%, #ffffff 110.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.home .home-content .home--text p {
  font-size: calc(1.2rem + 0.4vw);
  line-height: 154.02%;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #b5b5b5;
}
.home .home-content .home--btn {
  display: flex;
  position: relative;
  min-width: calc(12rem + 2vw);
  min-height: calc(12rem + 2vw);
  transform: translateX(-50px);
}
.home .home-content .home--btn .button {
  text-decoration: none;
  color: white;
  font-size: calc(1.2rem + 0.4vw);
  font-weight: 500;
  letter-spacing: 2px;
  min-width: calc(12rem + 2vw);
  min-height: calc(12rem + 2vw);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-bottom: 50%;
  position: relative;
  background: url("./img/btn-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding: 1rem;
  width: fit-content;
  position: relative;
}
.home .home-content .home--btn .button img {
  margin-bottom: 1rem;
  width: 2.4rem;
  transition: all 0.5s ease;
}
.home .home-content .home--btn .button span {
  background: linear-gradient(81.41deg, #ffffff 15.31%, #bb48de 110.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  display: block;
}
.home .home-content .home--btn .button:hover {
  box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.603);
}
.home .home-content .home--btn .button:hover img {
  transform: rotate(132deg);
}
.home .home-img {
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* padding: 0 3vw; */
  min-width: 350px;
  position: relative;
  animation: levitate 6s infinite;
}
.home .home-img img {
  min-width: 300px;
  /* animation: levitate 6s infinite; */
}
@keyframes levitate {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
.home .stat {
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.home .stat .circleStat {
  margin: 1rem 0;
}

/* #endregion */
/*#region   Nos Services  */
#services {
  /* background: url("img/service-bg.png"); */
  padding: var(--padding-second-) var(--padding-main-);
}
#services .service-container {
  margin: calc(4rem + 6vh) 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.process {
  padding: var(--padding-second-) 0 0 0;
}
.process_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 40px;
}
/* #endregion */
/*#region   Besoin d'aide  */
#besoindaide {
  padding: var(--padding-second-) var(--padding-main-);
  overflow-x: hidden;
}
#besoindaide .besoindaide__content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: calc(2.3rem + 2vw);
}
#besoindaide .besoindaide__content ul {
  list-style: square;
  display: flex;
  flex-direction: column;
  gap: calc(1.5rem + 1vw);
  padding-left: 2rem;
  margin-top: 2rem;
}
#besoindaide .besoindaide__content ul li {
  color: var(--secondary-color-);
  font-size: calc(var(--p-main-) + 0.4vw);
  line-height: 1.5;
}
#besoindaide .besoindaide__content .imgBox {
  flex-grow: 1;
  max-width: 480px;
  min-width: 200px;
}
#besoindaide .besoindaide__content img {
  margin-left: 1rem;
  width: 100%;
  /*   max-width: 480px;
  min-width: 200px; */
}
#besoindaide .btn-devis {
  display: flex;
  justify-content: flex-end;
}
/* #endregion */
/*#region   Portfolio  */
#portfolio {
  padding: var(--padding-main-);
  min-height: 100vh;
  /* background: url("img/service-bg.png"); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
#portfolio h2 {
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: var(--h2-main-);
}
#portfolio .portfolio--text {
  font-size: calc(1rem + 1vw);
  color: white;
  font-weight: 300;
  text-align: center;
  line-height: 1.8;
  max-width: calc(10rem + 60vw);
}
#portfolio .pageSelector {
  margin-top: 4vw;
  display: flex;
  margin-bottom: 4vh;
}
#portfolio .pageSelector button {
  background: #22003d;
  color: white;
  font-size: calc(1rem + 0.5vw);
  font-family: "Exo 2", sans-serif;
  text-decoration: none;
  padding: 1rem calc(1rem + 4vw);
  border: 1px solid white;
  cursor: pointer;
  transition: all 0.3s ease;
  display: block;
}
#portfolio .pageSelector button:first-child {
  border-radius: 20px 0 0 20px;
}
#portfolio .pageSelector button:last-child {
  border-radius: 0 20px 20px 0;
}
#portfolio .pageSelector button.active {
  background: linear-gradient(
    34.66deg,
    #480988 17.74%,
    #a551fa 53.12%,
    #a551fa 54.04%,
    rgba(255, 255, 255, 0.67) 87.27%
  );
}
#portfolio .demo-work {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
#portfolio .demo-work .pagework {
  display: flex;
  justify-content: center;
  width: 0%;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transform: translateY(100%);
  flex-wrap: wrap;
  z-index: -1;
  visibility: 0;
}

#portfolio .demo-work .pagework.active {
  position: relative;
  transform: translateY(0%);
  opacity: 1;
  visibility: 1;
  width: 100%;
  z-index: 1;
  transition: opacity 0.5s ease, transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
#portfolio .demo-work .pagework img {
  max-width: 300px;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  display: block;
  position: relative;
}
#portfolio .demo-work .pagework .imgContainer {
  position: relative;
  border-radius: 20px;
  margin: 1rem 3rem;
}
#portfolio .demo-work .pagework .imgContainer:hover .text-image {
  opacity: 1;
  cursor: pointer;
}
#portfolio .demo-work .pagework .imgContainer .text-image {
  color: white;
  position: absolute;
  top: 0;
  left: auto;
  z-index: 2;
  width: 100%;
  height: 100%;
  text-align: center;
  background: linear-gradient(
    34.66deg,
    rgba(72, 9, 136, 0.81) 17.74%,
    rgba(165, 81, 250, 0.79) 65.44%,
    rgba(255, 255, 255, 0.67) 87.27%
  );
  backdrop-filter: blur(2.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  opacity: 0;
  transition: all 0.5s ease;
}
#portfolio .demo-work .pagework2 .imgContainer .text-image,
#portfolio .demo-work .pagework3 .imgContainer .text-image {
  opacity: 1;
}
#portfolio .demo-work .pagework .imgContainer .text-image h3 {
  font-size: calc(1rem + 0.6vw);
  margin-bottom: 0.5rem;
}
#portfolio .demo-work .pagework .imgContainer .text-image h3.coming-soon {
  font-size: calc(1.5rem + 0.8vw);
}
#portfolio .demo-work .pagework .imgContainer .text-image p {
  font-size: calc(1rem + 0.6vw);
}
#portfolio .demo-work .pagework .imgContainer .text-image a,
#portfolio .demo-work .pagework .imgContainer .text-image .coming-soon {
  font-size: calc(1.2rem + 0.6vw);
  color: white;
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0.5rem 0;
  transition: all 0.5s ease;
}
#portfolio .demo-work .pagework .imgContainer .text-image a:hover {
  color: #a5a5a5;
}
/* #endregion */
/*#region   Questions  */
.container__faq {
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  background: var(--color-light-grey-);
  padding: var(--padding-second-) var(--padding-main-);
}
.container__faq h2 {
  margin-bottom: 5rem;
}
/* #endregion */
/*#region   Contact-Us  */
#contactUs {
  display: flex;
  flex-direction: column;
  /* background: url("img/service-bg.png"); */
  justify-content: center;
  align-items: center;
  color: white;
  padding: var(--padding-second-) var(--padding-main-);
  overflow-x: hidden;
}
#contactUs .contact--title {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: calc(4rem + 5vw);
}
#contactUs .contact--title h2 {
  font-size: calc(var(--h2-main-) + 1vw);
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
  align-self: flex-start;
}
#contactUs .contact--title h3 {
  font-size: calc(1.3rem + 3vw);
  font-family: "Exo 2", sans-serif;
  font-weight: 700;
  align-self: center;
  color: #b16efd8c;
}
#contactUs .contact--title.show h3 span:hover {
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.452);
}
#contactUs .contact--title.show h3 span {
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0);
  animation: glowing 2s;
  animation-iteration-count: 1;
  cursor: default;
  animation-delay: calc(var(--delay-base) + calc(0.1s * var(--index)));
}
@keyframes glowing {
  0% {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0);
  }
  25% {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.452);
  }
  75% {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0);
  }
  100% {
    text-shadow: 0px 0px 8px rgba(255, 255, 255, 0);
  }
}
#contactUs .contact--content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
#contactUs .contact--content .form {
  flex-grow: 1;
}
#contactUs .contact--content .form .loading-form {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#contactUs .contact--content .form .loading-form p {
  font-size: var(--p-main-);
  margin-top: 1rem;
}
#contactUs .contact--content .form .load-wrapp {
  margin: 0 10px 10px 0;
  padding: 20px 20px 20px;
  border-radius: 5px;
}
#contactUs .contact--content .form .line {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: var(--bg-secondary-color-);
}
#contactUs .contact--content .form .line:nth-child(2) {
  margin: 0 0.3rem;
}
#contactUs .contact--content .form .load-3 .line:nth-last-child(1) {
  animation: loadingC 1s 0.1s cubic-bezier(0.2, 1.15, 0.74, 1.24) infinite;
}
#contactUs .contact--content .form .load-3 .line:nth-last-child(2) {
  animation: loadingC 1s 0.2s cubic-bezier(0.2, 1.15, 0.74, 1.24) infinite;
}
#contactUs .contact--content .form .load-3 .line:nth-last-child(3) {
  animation: loadingC 1s 0.3s cubic-bezier(0.2, 1.15, 0.74, 1.24) infinite;
}
@keyframes loadingC {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}
#contactUs .contact--content .form .service-error {
  font-size: calc(1.5rem + 0.4vw);
  margin-bottom: 1rem;
  margin-left: 1rem;
}
#contactUs .contact--content .form .container-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}
#contactUs .contact--content .form .container-form form {
  width: 100%;
}
.path-animate {
  animation: draw 0.5s linear forwards;
}
@keyframes draw {
  from {
    stroke-dashoffset: 110;
  }
  to {
    stroke-dashoffset: 0;
  }
}
#contactUs .contact--content .form .success-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: calc(1.5rem + 0.4vw);
  margin-bottom: 1rem;
  margin-left: 1rem;
}
#contactUs .contact--content .form .success-message .svg-container {
  height: 60px;
  width: 60px;
  background-color: var(--main-color-);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-bottom: 1.5rem;
  animation: rotate 0.5s cubic-bezier(0.2, 1.15, 0.74, 1.24) 0.8s;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
#contactUs .contact--content .form .success-message h2 {
  margin-bottom: 1rem;
}
#contactUs .contact--content .form .button-services {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
}
#contactUs .contact--content .form .button-services .label-btn-services {
  font-size: calc(1rem + 0.4vw);
  color: var(--secondary-color-);
  margin-bottom: 20px;
}
#contactUs .contact--content .form .button-services .button-servicesContainer {
  display: flex;
}
#contactUs
  .contact--content
  .form
  .button-services
  .button-servicesContainer
  label {
  cursor: pointer;
  flex-grow: 1;
}
#contactUs
  .contact--content
  .form
  .button-services
  .button-servicesContainer
  label
  input[type="checkbox"] {
  display: none;
}
#contactUs
  .contact--content
  .form
  .button-services
  .button-servicesContainer
  label
  input[type="checkbox"]:checked
  ~ span {
  background: var(--secondary-color-);
  color: var(--bg-main-color-);
  box-shadow: 0 0 15px 2px rgba(255, 255, 255, 0.151);
}
#contactUs
  .contact--content
  .form
  .button-services
  .button-servicesContainer
  label
  input[type="checkbox"]:hover
  ~ span {
  outline: 1px var(--secondary-color-) solid;
}
#contactUs
  .contact--content
  .form
  .button-services
  .button-servicesContainer
  label
  span {
  font-size: calc(1rem + 0.4vw);
  font-weight: 300;
  display: block;
  color: var(--secondary-color-);
  border: 1px solid var(--secondary-color-);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 0.7rem 0;
  margin: 0 1rem;
  transition: all 0.5s ease, border 0.1s;
}
#contactUs .contact--content .form form .input {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem 1rem;
}
#contactUs .contact--content .form form .input label {
  font-size: calc(1rem + 0.4vw);
  color: var(--secondary-color-);
  margin-bottom: 5px;
}
#contactUs .contact--content .form form .input input {
  min-height: 40px;
  background: transparent;
  border: none;
  border-bottom: 4px solid var(--secondary-color-);
  color: var(--secondary-color-);
  font-size: calc(0.7rem + 0.5vw);
  color: var(--secondary-color-);
  font-weight: 500;
  transition: all 0.5s ease;
  padding: 0 1rem;
  outline: 0;
}
#contactUs .contact--content .form form .input textarea {
  min-height: 100px;
  background: transparent;
  border: none;
  border-bottom: 4px solid var(--secondary-color-);
  color: var(--secondary-color-);
  font-size: calc(0.7rem + 0.5vw);
  color: var(--secondary-color-);
  font-weight: 500;
  transition: all 0.5s ease;
  padding: 0 1rem;
  outline: 0;
}
#contactUs .contact--content .form form .input textarea::placeholder {
  color: var(--secondary-color-);
}
#contactUs .contact--content .form form .input input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--secondary-color-) inset !important;
}
#contactUs .contact--content .form form .row {
  display: flex;
}
#contactUs .contact--content .form form .btn-send {
  margin-top: 5rem;
  margin-left: 10px;
}
#contactUs .contact--content .form form .btn-send input {
  background-color: transparent;
  border: none;
  color: var(--secondary-color-);
  font-family: "Exo 2", sans-serif;
  font-size: calc(1.6rem + 0.8vw);
  font-weight: 600;
  transition: all 0.5s ease;
  padding-right: 10px;
}
#contactUs .contact--content .form form .btn-send input:hover {
  cursor: pointer;
  padding-right: 30px;
}
#contactUs .contact--content .form form .btn-send input:hover ~ img {
  transform: rotate(43deg);
}
#contactUs .contact--content .form form .btn-send img {
  width: 25px;
  transition: all 0.5s ease;
}
#contactUs .contact--content .contact--text {
  padding: 0 calc(2rem + 3vw);
  min-width: 300px;
  max-width: 500px;
}
#contactUs .contact--content .contact--text p {
  font-size: calc(1rem + 0.5vw);
  color: var(--secondary-color-);
  width: 100%;
  line-height: 1.5;
}
/* #endregion */

/*#region   Animation  */
/*#region   Accueil  */
.home .home-content .home--text h1 span.hidden {
  transition: transform 0.5s ease, opacity 1s ease;
  opacity: 0;
  visibility: hidden;
}
.home .home-content .home--text h1 span.hidden:nth-child(1) {
  transform: translateY(-100%);
  visibility: hidden;
}
.home .home-content .home--text h1 span.hidden.show:nth-child(1) {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.home .home-content .home--text h1 span.hidden:nth-child(2) {
  transform: translateX(-100%);
  transition: transform 0.7s ease 0.2s;
  visibility: hidden;
}
.home .home-content .home--text h1 span.hidden.show:nth-child(2) {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}
.home .home-content .home--text h1 span.hidden:nth-child(3) {
  transform: translateY(100%);
  transition: transform 0.5s ease 0.6s;
  visibility: hidden;
}
.home .home-content .home--text h1 span.hidden.show:nth-child(3) {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.home--text .home-bande.hidden {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease 1.1s;
  visibility: hidden;
}
.home--text .home-bande.hidden.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.home--text .texte.hidden {
  opacity: 0;
  transform: translateY(-60%);
  transition: all 0.5s ease 1.1s;
  visibility: hidden;
}
.home--text .texte.hidden.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.home .home-content .home--btn .button.hidden {
  opacity: 0;
  transform: rotate(90deg) scale(0);
  transition: opacity 0.3s ease 1.6s, transform 0.3s ease 1.6s,
    box-shadow 0.3s ease;
  visibility: hidden;
}
.home .home-content .home--btn .button.hidden.show {
  opacity: 1;
  transform: rotate(0) scale(1);
  visibility: visible;
}
.home .home-img.hidden {
  opacity: 0;
  filter: blur(10px);
  transition: all 0.5s ease 0s;
  visibility: hidden;
}
.home .home-img.hidden.show {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
}
.home .stat.hidden .circleStat {
  transform: translateY(70%);
  opacity: 0;
  transition: all 0.5s ease;
  visibility: hidden;
}
.home .stat.hidden.show .circleStat {
  transform: translateY(0);
  transition: all 0.5s ease 0.6s;
  opacity: 1;
  visibility: visible;
}
/* #endregion */
/*#region   Service */
.title-section .above-title.hidden {
  opacity: 0;
  filter: blur(10px);
  transition: all 0.5s ease 0.2s;
  visibility: hidden;
}
.title-section .above-title.hidden.show {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
}
.title-section .title-h2.hidden {
  opacity: 0;
  filter: blur(10px);
  transform: translateY(50%);
  transition: all 0.5s ease 0.3s;
  visibility: hidden;
}
.title-section .title-h2.hidden.show {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
  visibility: visible;
}
.iconBox.hidden .icon-circle {
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.5s ease 0.6s;
  visibility: hidden;
}
.iconBox.hidden.show .icon-circle {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.iconBox.hidden .icon-title {
  opacity: 0;
  transform: translateY(-50%);
  transition: all 0.5s cubic-bezier(0.47, 1.55, 0.66, 0.95) 0.9s;
  visibility: hidden;
}
.iconBox.hidden.show .icon-title {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.iconBox.hidden .icon-desc {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.5s cubic-bezier(0.47, 1.55, 0.66, 0.95) 0.9s;
  visibility: hidden;
}
.iconBox.hidden.show .icon-desc {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.title-h2-l.hidden {
  opacity: 0;
  transform: translateX(-30%);
  transition: all 0.5s ease;
  visibility: hidden;
}
.title-h2-l.hidden.show {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
.step.hidden .step--title {
  opacity: 0;
  filter: blur(10px);
  transition: all 0.5s ease 0.8s;
  visibility: hidden;
}
/* .process_content .step.hidden:nth-child(2) .step--title {
  transition: all 0.5s ease 1.2s;
}
.process_content .step.hidden:nth-child(3) .step--title {
  transition: all 0.5s ease 1.2s;
} */
.step.hidden.show .step--title {
  opacity: 1;
  filter: blur(0);
  visibility: visible;
}
.step.hidden .step--text {
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.5s ease 1s;
  visibility: hidden;
}
/* .process_content .step.hidden:nth-child(2) .step--text {
  transition: all 0.5s ease 1.4s;
}
.process_content .step.hidden:nth-child(3) .step--text {
  transition: all 0.5s ease 1.4s;
} */
.step.hidden.show .step--text {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
.hidden.span {
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.5s ease 0.1s;
  visibility: hidden;
}
/* .process_content .step:nth-child(2) .hidden_span {
  transition: all 0.4s ease 0.3s;
}
.process_content .step:nth-child(3) .hidden_span {
  transition: all 0.4s ease 0.5s;
} */
.hidden.span.show {
  transform: scaleY(100%);
  visibility: visible;
}
.hidden.number {
  opacity: 0;
  transition: all 0.3s ease 0.5s;
  /*   transition: opacity 0.5s ease 0.4s, filter 0.5s ease 0.4s;
  transition: box-shadow 0.3s ease 0s; */
  filter: blur(10px);
  visibility: hidden;
}
/* .process_content .step:nth-child(2) .hidden_number {
  transition: opacity 0.5s ease 0.8s, filter 0.5s ease 0.8s;
}
.process_content .step:nth-child(3) .hidden_number {
  transition: opacity 0.5s ease 1s, filter 0.5s ease 1s;
} */
.hidden.number.show {
  opacity: 1;
  filter: blur(0);
  box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.281);
  visibility: visible;
}
.step.illuminated {
  transition: all 0.5s ease;
  transform: scale(1.1);
}
.step.show {
  transition: all 0.5s ease;
}
.step.show.illuminated {
  transition: all 0.5s ease;
}
.step.illuminated .step--number .number.show {
  box-shadow: 0 0 15px 2px rgba(255, 255, 255, 0.151);
  visibility: visible;
}
/* #endregion */
/*#region   Portfolio */
#portfolio .portfolio--text.hidden {
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.5s ease 0.4s;
  visibility: hidden;
}
#portfolio .portfolio--text.hidden.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
#portfolio .pageSelector.hidden {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease 0.6s;
  visibility: hidden;
}
#portfolio .pageSelector.hidden.show {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
#portfolio .demo-work .pagework1 .imgContainer.hidden {
  opacity: 0;
  transform: scale(0);
  transition: all 0.5s ease 0.2s;
  visibility: hidden;
}
#portfolio .demo-work .pagework1 .imgContainer.hidden.show {
  opacity: 1;
  transform: scale(1);
  visibility: visible;
}
/* #endregion */
/*#region   Contact */
#contactUs .contact--title.hidden h2 {
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease 0.2s;
  visibility: hidden;
}
#contactUs .contact--title.hidden.show h2 {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}
#contactUs .contact--title.hidden h3 {
  opacity: 0;
  transform: translateX(-20%);
  transition: all 0.5s ease 0.5s;
  visibility: hidden;
}
#contactUs .contact--title.hidden.show h3 {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
#contactUs .contact--content .contact--text.hidden {
  opacity: 0;
  transform: translateX(50%);
  transition: all 0.5s ease 0.5s;
  visibility: hidden;
}
#contactUs .contact--content .contact--text.hidden.show {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}
.button-servicesContainer label.hidden {
  opacity: 0;
  transition: all 0.5s ease 0.7s;
  visibility: hidden;
}
.button-servicesContainer label.hidden:nth-child(2) {
  transform: translateX(-50%);
  transition: all 1s cubic-bezier(0.47, 1.55, 0.66, 0.95) 1s;
  visibility: hidden;
}
.button-servicesContainer label.hidden:last-child {
  transform: translateX(-100%);
  transition: all 1s cubic-bezier(0.47, 1.55, 0.66, 0.95) 1s;
  visibility: hidden;
}
.button-servicesContainer label.hidden.show {
  opacity: 1;
  visibility: visible;
}
.button-servicesContainer label.hidden.show:nth-child(2) {
  transform: translateX(0);
  visibility: visible;
}
.button-servicesContainer label.hidden.show:last-child {
  transform: translateX(0);
  visibility: visible;
}
.label-btn-services.hidden {
  transform: translateY(50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.47, 1.55, 0.66, 0.95) 0.5s;
  visibility: hidden;
}
.label-btn-services.hidden.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.form .input.hidden,
.form .btn-send.hidden {
  transform: translateY(50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.47, 1.55, 0.66, 0.95) 1s;
  visibility: hidden;
}
.form .input.hidden.show,
.form .btn-send.hidden.show {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
/* #endregion */
/*#region   Test  */
.hidden {
  opacity: 0;
  transition: all 0.5s ease 0.2s;
  visibility: hidden;
}
.hidden.show {
  opacity: 1;
  visibility: visible;
}

.hidden.d1 {
  transition-delay: 0.3s;
}
.hidden.d2 {
  transition-delay: 0.4s;
}
.hidden.d3 {
  transition-delay: 0.5s;
}
.hidden.d4 {
  transition-delay: 0.6s;
}

.hidden.right {
  transform: translateX(30%);
  visibility: hidden;
}
.hidden.right.show {
  transform: translateX(0);
  visibility: visible;
}

.hidden.left {
  transform: translateX(-30%);
  visibility: hidden;
}
.hidden.left.show {
  transform: translateX(0);
  visibility: visible;
}

.hidden.top {
  transform: translateY(-100px);
  visibility: hidden;
}
.hidden.top.show {
  transform: translateY(0);
  visibility: visible;
}

.hidden.bottom {
  transform: translateY(100px);
  visibility: hidden;
}
.hidden.bottom.show {
  transform: translateY(0);
  visibility: visible;
}

.hidden.blur {
  filter: blur(10px);
  visibility: hidden;
}
.hidden.blur.show {
  filter: blur(0);
  visibility: visible;
}
/* #endregion */
/* #endregion */

/*#region Media-1590px */
@media screen and (max-width: 1590px) {
  #besoindaide .besoindaide__content {
    justify-content: center;
    gap: 20px;
  }
  #besoindaide .besoindaide__content img {
    margin-left: 0;
    margin-bottom: 4rem;
  }
}
/* #endregion */
/*#region Media-1160px */
@media screen and (max-width: 1160px) {
  #contactUs .contact--content .contact--text {
    margin-top: 5rem;
    max-width: unset;
  }
  #contactUs .contact--content .form form .row {
    display: flex;
  }
  .home {
    padding: 120px var(--padding-main-) 0 var(--padding-main-);
  }
  .home .home-content .home--textContainer {
    width: 100%;
  }
  .home .stat {
    margin-top: 2rem;
  }
  .home .home-img {
    margin-top: 3rem;
  }
}
/* #endregion */
/*#region Media-600px */
@media screen and (max-width: 600px) {
  :root {
    --min-width-500-: 300px;
  }
  .home .home-content .home--text h1 {
    max-width: 340px;
    font-size: calc(3.5rem + 3vw);
    margin: 1rem 0 4rem;
  }
  .home .home-content .home--textContainer {
    flex-wrap: wrap;
    align-items: center;
  }
  .home .home-content .home--btn {
    transform: translateX(0);
    margin-top: 4rem;
  }
  .home .home-img.hidden {
    transition: all 0.5s ease 0.2s;
  }
  #besoindaide .btn-devis {
    display: flex;
    justify-content: center;
  }
  #portfolio {
    overflow-x: hidden;
  }
}
/* #endregion */
/*#region Media-450px */
@media screen and (max-width: 450px) {
  :root {
    --padding-main-: calc(3rem + 2vw);
  }
  #contactUs .contact--content .form form .row {
    flex-direction: column;
  }
  #contactUs .contact--content .contact--text {
    padding: 0;
  }
  #contactUs .contact--title h2 {
    margin-bottom: 5vw;
  }
  .home .home-content .home--btn {
    margin-top: 5rem;
  }
  .home .home-img {
    width: 40%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* padding: 0 3vw; */
    min-width: 300px;
    position: relative;
  }
  .home .home-img img {
    min-height: unset;
    height: auto;
  }
  #contactUs .contact--title h3 {
    font-size: 1.8rem;
  }
  .home .home-img img {
    max-height: 320px;
  }
}
/* #endregion */
